import React, {FunctionComponent} from 'react';

const Schema: FunctionComponent = () => {
    return (
        <script type='application/ld+json' dangerouslySetInnerHTML={{
            __html: `{
                "@context":"https://schema.org",
                "@type":"Organization",
                "name":"sanofi-aventis Netherlands B.V.",
                "address":"Paasheuvelweg 25, 1105 BP Amsterdam",
                "url": "https://www.sanofi.nl"
            }`
        }}/>
    );
};
export default Schema;
