import React, {FunctionComponent} from 'react';
import Schema from './Schema';
import {Helmet} from 'react-helmet';

interface Props {
    pageTitle?: string;
}

const HtmlHead: FunctionComponent<Props> = ({pageTitle}) => {
    const {head}: { head: { title: string, description: string, keywords: string, baseUrl: string } } = require(`../../content/${process.env.THEME}/head.json`);
    const {title, description, keywords, baseUrl} = head;
    const fullTitle = pageTitle ? pageTitle + ' | ' + title : title;

    return (
        <Helmet>
            <title>{fullTitle}</title>
            <Schema/>
            <meta name='description' content={description} key='description'/>
            <meta name='keywords' content={keywords} key='keywords'/>
            <meta httpEquiv='X-UA-Compatible' content='IE=edge'/>
            <link rel='canonical' href={baseUrl}/>
        </Helmet>
    );
};

export default HtmlHead;
